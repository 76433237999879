export const environment = {
	production: true,
	region: 'ap-southeast-2',
	endpoint: 'https://api.reali.au',
	wsEndPoint: 'wss://api.reali.au',
	appName: 'REALI',
	logLevel: 'debug',
	buildId: process.env.NG_APP_BUILD_ID || 'local',
	defaultCollectionLimit: 10,
	enableSentry: true,
	defaultTestEmailAddress: "",
version: "prod_853",
	baseDomain: 'reali.au',
	readApiToken: "39cafcf2862b2e6627657b726f76d0371fd0e690",
	analyticsTrackingCode: 'G-FX3T1HG3NC',
	defaultSsoPool: 'ap-southeast-2_QM5ZnkQJw',
	defaultSsoDomain: 'sso.reali.au'
};
